import React from 'react'

const Testing = () => {
  return (
    <div>
      <h1>SMS test</h1>
      <a href="sms://+351914765874?&body=Hello%20World">Send Text</a>
    </div>
  )
}

export default Testing
